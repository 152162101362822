* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

html,
body,
#root,
.appp {
  background-color: #fff;
  color: black;
}
