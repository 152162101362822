@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Playfair+Display:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Fauna+One&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app {
  height: 100%;
  background-color: #010026;
  @apply text-white;
}
* {
  -moz-font-feature-settings: 'ss02';
  -webkit-font-feature-settings: 'ss02';
  font-feature-settings: 'ss02';
}
@import url('./Webfonts/fontiran.css');
body {
  font-family: IRANSansX;
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
input,
textarea {
  font-family: IRANSansX;
}
::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-track {
  border-radius: 4px;
}
::-webkit-scrollbar-thumb {
  background: #afb6d055;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #afb6d0;
}
